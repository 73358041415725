<template>
  <section>
    <div class="home-sub-title text-center">
      <p>5501 Adams ST Unit C Matteson, IL 60443</p>
    </div>
    <div class="home-welcome text-center">
      <h2>Consignment</h2>
    </div>
  </section>
  <section class="my-5">
    <div class="container">
      <div class="row">
        <div class="col-7 mx-auto">
          <p class="mb-3">
            <span class="fw-bold">E & A Truck Sales</span> has a very open and
            honest consignment structure. We are willing to help use our
            contacts and stream of truck buyers to help move your equipment. We
            will create a great advertisement for your equipment and handle the
            entire sales process from start to end. All we ask of you is to
            schedule an inspection of the equipment to make sure it is DOT legal
            and safe. Our sales staff looks forward to helping you.
          </p>
          <p class="text-danger mb-2">All fields below are required.</p>
          <form @submit.prevent="">
            <div class="input-wrapper gap-3 mb-2">
              <input
                v-model="firstName"
                class="form-control border"
                :class="{ 'border-danger': !firstName && isEmpty }"
                type="text"
                placeholder="First Name"
              />
              <input
                v-model="lastName"
                class="form-control border"
                :class="{ 'border-danger': !lastName && isEmpty }"
                type="text"
                placeholder="Last Name"
              />
              <input
                v-model="email"
                class="form-control border"
                :class="{ 'border-danger': !email && isEmpty }"
                type="email"
                placeholder="E-mail Address"
              />
            </div>
            <div class="input-wrapper gap-3 mb-2">
              <input
                v-model="city"
                class="form-control border"
                :class="{ 'border-danger': !city && isEmpty }"
                type="text"
                placeholder="City"
              />
              <input
                v-model="state"
                class="form-control border"
                :class="{ 'border-danger': !state && isEmpty }"
                type="text"
                placeholder="State"
              />
              <input
                v-model="country"
                class="form-control border"
                :class="{ 'border-danger': !country && isEmpty }"
                type="text"
                placeholder="Country"
              />
            </div>
            <div class="mb-3">
              <textarea
                v-model="comment"
                class="form-control border"
                :class="{ 'border-danger': !comment && isEmpty }"
                placeholder="Comment"
              ></textarea>
            </div>
            <div class="d-flex justify-content-center">
              <button
                @click="submitForm"
                type="submit"
                class="d-flex justify-content-center gap-2 align-items-center"
              >
                Submit <base-loader v-if="loading"></base-loader>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <base-footer></base-footer>
</template>

<script>
import customAxios from "../api";
import { defineAsyncComponent } from "vue";
const BaseLoader = defineAsyncComponent(() =>
  import("../components/UI/BaseLoader.vue")
);
import { checkEmail } from "../utils";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  components: {
    BaseLoader,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      city: "",
      state: "",
      country: "",
      comment: "",
      isEmpty: false,
    };
  },
  computed: {
    loading() {
      return this.$store.state.isLoading;
    },
  },
  methods: {
    reset() {
      this.isEmpty = false;
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.city = "";
      this.state = "";
      this.country = "";
      this.comment = "";
    },
    async submitForm() {
      if (
        !this.firstName ||
        !this.lastName ||
        !this.email ||
        !this.city ||
        !this.state ||
        !this.country ||
        !this.comment ||
        checkEmail(this.email)
      ) {
        this.isEmpty = true;
        return;
      }
      try {
        this.$store.dispatch("changeLoading", true);
        await customAxios.post("simple-form/", {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          recipient_email: this.recEmail,
          message: this.message,
          country: this.country,
          state: this.state,
          city: this.city,
        });
        this.$store.dispatch("changeLoading", false);
        this.reset();
        toast.success("Success!");
      } catch (e) {
        this.$store.dispatch("changeLoading", false);
        this.$store.dispatch("setError", e);
      }
    },
  },
};
</script>

<style scoped>
.d-flex input {
  flex: 1;
}
form button {
  width: 30%;
  background: #0057b7;
  color: #fff;
  padding: 4px 2.5rem;
  border-radius: 0.25rem;
  border: none;
}
.input-wrapper {
  display: flex;
}
form button:hover {
  background: #024690;
}
input.border-danger::placeholder,
textarea.border-danger::placeholder {
  color: #dc3545;
}
@media screen and (max-width: 992px) {
  .link-title {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .input-wrapper {
    flex-direction: column;
    gap: 6px !important;
  }
  button {
    padding: 4px 8px !important;
  }
}
@media screen and (max-width: 576px) {
  p,
  input::placeholder {
    font-size: 0.9rem;
  }
}
</style>
